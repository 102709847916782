<template>
  <div>
    <!--begin::ListOperations -->
    <!-- DEV -->

    <div class="row mb-4" v-if="showDataDebug">
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre class="mb-0"><b>filteredOperationsLength:</b> {{ filteredOperationsLength }}</pre>
          <pre class="mb-0"><b>currentPage:</b> {{ currentPage }}</pre>
          <pre class="mb-0"><b>perPage:</b> {{ perPage }}</pre>

          <pre class="mb-0" :class="!runCallBySearch ? 'text-danger' : 'text-success'"><b>runCallBySearch:</b> {{ runCallBySearch }}</pre>
          <pre class="mb-0 text-warning"><b>searchStatus:</b> {{ searchStatus }}</pre>
          <pre class="mb-0 text-warning"><b>Filter:</b> {{ filter }}</pre>

          <pre class="mb-0 text-info"><b>this.$route.params:</b> {{ this.$route.params }}</pre>
          <pre class="mb-0 text-info"><b>selectVal:</b> {{ selectVal }}</pre>
          <pre class="mb-0 text-opalean-tertiary"><b>filterChartersClaim:</b> {{ filterChartersClaim }} ( <b>getUserMainRole:</b> {{ getUserMainRole }} )</pre>
        </div>
      </div>

      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre class="mb-0"><b>Stored datas:</b> {{ StoredDatas.datas.filteredOperations }}</pre>
        </div>
      </div>
    </div>

    <div class="card card-custom --card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 bg-opalean-white" v-if="this.showHeader">
        <h3 class="card-title align-items-start flex-column my-1">
          <span class="card-label font-weight-bolder font-size-h6 text-dark" v-translate>Operations</span>
          <span class="text-muted mt-2 font-weight-bold font-size-sm" v-translate>Latest operations</span>
        </h3>
        <div class="card-toolbar">
          <a class="btn btn-transparent btn-hover-opalean-gray-medium text-hover-white p-2 handle"
            ><span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"><inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"></inline-svg></span>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body p-0">
        <!-- Create -->
        <b-button
          v-if="this.showCreateButton"
          to="/operations/create"
          size="lg"
          block
          variant="secondary"
          class="p-5 rounded-bottom-0 rounded-sm text-left font-weight-bold m-0"
          ><i class="flaticon2-plus"></i> <translate>Create</translate></b-button
        >

        <!-- Search -->
        <b-button
          v-if="this.showSearch"
          v-b-toggle.list-search
          size="lg"
          block
          variant="opalean-gray-medium"
          class="p-5 rounded-sm text-left font-weight-bold m-0"
          :class="[this.showFilters === true ? 'rounded-bottom-0' : '', this.showCreateButton === true ? 'rounded-top-0' : '']"
          ><i class="flaticon2-search-1"></i> <translate>Search</translate>
          <span v-if="!haveAnyStoredSearchs()" class="float-right"><i class="la la-filter"></i></span>
        </b-button>
        <b-collapse id="list-search" class="mt-0">
          <b-card
            class="border-top-0 rounded-top-0 rounded-sm"
            body-class="px-5 bg-opalean-whiter"
            :class="[this.showFilters === true ? 'rounded-bottom-0' : '', this.showCreateButton === true ? 'rounded-top-0' : '']"
          >
            <p class="card-text font-size-lg font-weight-bold" v-translate>
              You can run a search query with the attributes below to return a selected amount of operations
            </p>

            <b-container fluid class="px-0">
              <!-- User Interface controls -->
              <b-row>
                <b-col lg="3" class="">
                  <b-form-group
                    :label="$gettext('Limit to partners...')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Choose a partner to filter the query')"
                    label-for="search-list-partner"
                  >
                    <Multiselect
                      id="search-list-partner"
                      v-model="searchPartner"
                      :options="searchedPartners != null ? searchedPartners : Partners"
                      label="Name"
                      track-by="Name"
                      :placeholder="$gettext('Select a partner...')"
                      class="mt-4"
                      :internal-search="false"
                      @search-change="ignoreAccents"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="">{{ props.option.Name }}</span> <span class="opacity-50">{{ props.option.City }}</span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span class="">{{ props.option.Name }}</span> <span class="opacity-50">{{ props.option.City }}</span>
                      </template>
                    </Multiselect>
                  </b-form-group>
                </b-col>

                <b-col lg="3" class="">
                  <b-form-group
                    :label="$gettext('From date')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Fill the date ( formatted as DD/MM/YYYY )')"
                    label-for="search-from-date"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input
                        id="search-from-date"
                        :value="formatDate(searchFromDate)"
                        type="text"
                        :placeholder="$gettext('Set the starting date')"
                        autocomplete="off"
                        @change="(value) => onChangeDate(value, 'searchFromDate')"
                        :style="{ borderColor: isDateValid || isSearchFromDate ? '' : '#dc3545' }"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="searchFromDate"
                          button-only
                          right
                          dropup
                          aria-controls="search-from-date"
                          @context="(context) => onDateContext(context, 'searchFromDate')"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :state="isSearchFromDate"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col lg="3" class="">
                  <b-form-group
                    :label="$gettext('To date')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Fill the date ( formatted as DD/MM/YYYY )')"
                    label-for="search-to-date"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input
                        id="search-to-date"
                        :value="formatDate(searchToDate)"
                        type="text"
                        :placeholder="$gettext('Set the starting date')"
                        autocomplete="off"
                        @change="(value) => onChangeDate(value, 'searchToDate')"
                        :style="{ borderColor: isDateValid || isSearchToDate ? '' : '#dc3545' }"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="searchToDate"
                          button-only
                          right
                          dropup
                          aria-controls="search-to-date"
                          @context="(context) => onDateContext(context, 'searchToDate')"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :state="isSearchToDate"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <!-- 
                <b-col lg="6" class="">
                  <b-form-group
                    :label="$gettext('Limit operations to...')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Default limit is defined to') + ' ' + getUserGetOperationsLimit + ' ' + $gettext('operations maximum.')"
                    label-for="search-limit-input"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input id="search-limit-input" type="text" v-model="searchLimit" v-mask="{ mask: '9', repeat: 5, greedy: false }"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                -->

                <b-col lg="3" class="">
                  <b-form-group
                    :label="$gettext('Limit status operations to...')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    label-for="search-status-select"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <select id="search-status-select" class="form-control form-control-lg" @click.stop.prevent v-model="searchStatus">
                        <option :value="undefined" v-translate>Select...</option>
                        <option :value="status.id" v-for="(status, index) in OperationStatus.filter((s) => s.actions.includes('search'))" :key="index">
                          {{ $gettext(status.name) }}
                        </option>
                      </select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col lg="6" class="" v-if="searchPartner" :disabled="!searchPartner">
                  <b-form-group
                    :label="$gettext('Filtering operations on')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('6 charaters minimum for search')"
                    label-for="search-input"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input id="search-input" v-model="searchCMR" type="search" :placeholder="$gettext('CMR')"></b-form-input>

                      <b-input-group-append>
                        <b-button :disabled="!searchCMR" @click="searchCMR = ''" v-translate>Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col lg="12">
                  <b-button-group class="w-100" size="lg">
                    <b-button
                      variant="outline-opalean-gray-medium"
                      size="lg"
                      class="btn-hover-success font-weight-bold"
                      v-if="isValidSearchFilter && isDateValid"
                      :disabled="(!isDateValid && !isValidSearchFilter) || (searchCMR.length > 0 && (searchCMR.length < 6 || searchCMR.length > 20))"
                      @click="runSearchs()"
                      ><i class="flaticon2-search icon-md"></i> <translate>Search</translate></b-button
                    >
                    <b-button
                      variant="outline-opalean-gray-medium"
                      size="lg"
                      class="btn-hover-danger font-weight-bold"
                      v-if="!haveAnySearchs()"
                      :disabled="haveAnySearchs()"
                      @click="clearAllSearchs($event)"
                      ><i class="flaticon2-refresh-button icon-md"></i> <translate>Clear all</translate></b-button
                    >
                  </b-button-group>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-collapse>

        <!-- Filters -->
        <!-- <b-button
          v-if="this.showFilters"
          v-b-toggle.list-filters
          size="lg"
          block
          variant="opalean-gray-light"
          class="p-5 rounded-sm text-left font-weight-bold m-0"
          :class="[this.showSearch === true || this.showCreateButton === true ? 'rounded-top-0' : '']"
          ><i class="flaticon2-dashboard"></i> <translate>Filters</translate>
          <span v-if="!haveAnyFilters()" class="float-right"><i class="la la-filter"></i></span>
        </b-button>-->
        <b-button-group
          class="m-0 btn-block bg-opalean-gray-light rounded-sm"
          :class="[this.showSearch === true || this.showCreateButton === true ? 'rounded-top-0' : '']"
        >
          <b-button
            v-if="this.showFilters"
            v-b-toggle.list-filters
            size="lg"
            block
            variant="opalean-gray-light"
            class="p-5 rounded-sm text-left font-weight-bold m-0"
            :class="[this.showSearch === true || this.showCreateButton === true ? 'rounded-top-0' : '']"
            ><i class="flaticon2-dashboard"></i> <translate>Filters</translate>
            <span v-if="!haveAnyFilters()" class="float-right"><i class="la la-filter"></i></span>
            <span v-if="filterChartersClaim" class="float-right"><i class="la la-filter text-opalean-tertiary"></i></span>
          </b-button>
          <b-button hidden squared variant="opalean-gray-light" class="w-10" :to="{ name: 'route.operations.import' }"
            ><translate>Import your operations</translate>
          </b-button>
        </b-button-group>

        <b-collapse id="list-filters" class="mt-0">
          <b-card
            class="border-top-0 rounded-top-0 rounded-sm"
            body-class="px-5 bg-opalean-whiter"
            :class="[this.showSearch === true || this.showCreateButton === true ? 'rounded-top-0' : '']"
          >
            <p class="card-text font-size-lg font-weight-bold" v-translate>Filters</p>

            <b-container fluid class="px-0">
              <!-- User Interface controls -->
              <b-row>
                <!-- https://codepen.io/morgul/pen/qLeOoG -->
                <b-col lg="4" class="" v-for="key in fieldKeys" :key="key">
                  <b-form-group
                    :label="$gettext(getFilterLabel(key, 'name'))"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :label-for="`select-input-${key}`"
                  >
                    <select :key="key" class="form-control form-control-lg" @click.stop.prevent v-model="selectVal[key]">
                      <option :value="undefined" v-translate>Select...</option>
                      <option :value="option" v-for="option in listOptions[key]" :key="option">
                        {{ $gettext(getOptionNamebyKeys(key, option, "name")) }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>

                <b-col lg="6" class="">
                  <b-form-group
                    :label="$gettext('Filtering operations')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Examples : Auchan, Grimonprez, Grim, 0003, AF, Opalean, Dusseldofer, 11/12...')"
                    label-for="filter-input"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$gettext('Type to search')"></b-form-input>

                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''" v-translate>Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col lg="6">
                  <b-form-group
                    :label="$gettext('Filtering operations on')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Leave all unchecked to filter on all data')"
                    v-slot="{ ariaDescribedby }"
                    buttons
                  >
                    <b-form-checkbox-group v-model="filterOn" :aria-describedby="ariaDescribedby" size="lg">
                      <b-form-checkbox value="OperationID"><translate>ID</translate></b-form-checkbox>
                      <b-form-checkbox value="OperationCMR"><translate>CMR</translate></b-form-checkbox>
                      <b-form-checkbox value="_OperationDate"><translate>Date</translate></b-form-checkbox>
                      <b-form-checkbox value="_PartnerNames"><translate>Partners</translate></b-form-checkbox>
                      <b-form-checkbox value="_PartnerReferences"><translate>Reference</translate></b-form-checkbox>
                      <b-form-checkbox value="_PalletNames"><translate>Pallets</translate></b-form-checkbox>
                      <b-form-checkbox value="Manifest"><translate>Manifest</translate></b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>

                <b-col lg="12" v-if="this.getUserMainRole === 'TR'">
                  <b-col lg="6">
                    <div class="badge badge-lg bg-opalean-tertiary">
                      <b-form-checkbox
                        id="filterChartersClaim"
                        v-model="filterChartersClaim"
                        size="lg"
                        button-variant="dark"
                        name="filterChartersClaim"
                        class=""
                      >
                        {{ $gettext("Show only disputed transactions for charters") }}
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-col>

                <b-col lg="12">
                  <b-button
                    block
                    variant="outline-opalean-gray-medium"
                    size="lg"
                    class="btn-hover-danger font-weight-bold"
                    v-if="!haveAnyFilters()"
                    :disabled="haveAnyFilters()"
                    @click="clearAllFilters($event)"
                    ><i class="flaticon2-refresh-button icon-md"></i> <translate>Clear all</translate></b-button
                  >
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-collapse>

        <!-- Bulk actions -->
        <!-- <b-collapse v-model="isBulkVisible" id="list-bulk-actions" class="mt-0">
          <b-card v-model="isBulkVisible" class="border-top-0 rounded-top-0 rounded-sm" body-class="px-5 bg-opalean-whiter">
            <p class="card-text font-size-lg font-weight-bold"><i class="flaticon2-list-3"></i> <translate>Bulk actions</translate></p>
            <p>
              <strong><translate>Selected Operations</translate></strong
              ><br />
              <span v-for="(key, index) in selected" :key="index" class="label label-lg label-opalean-gray-medium label-inline font-weight-bold mr-2">{{
                key.OperationID
              }}</span>
            </p>
            <pre v-if="showDataDebug">{{ selected }}</pre>
          </b-card>
        </b-collapse> -->

        <!--begin::Table-->
        <b-table
          id="list-table"
          class="table table-vertical-center"
          :items="filteredItems"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusy"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @context-changed="onTableChanged"
          striped
          hover
          responsive="sm"
          thead-class="font-uppercase"
          :tbody-tr-class="rowClass"
          :foot-clone="showPagination"
          ref="selectableTable"
          stacked="sm"
          show-empty
        >
          <!-- selectable
          selected-variant="opalean-gray-light"
          select-mode="multi"
          @row-selected="onRowSelected" -->

          <!-- Busy state -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="d-block" v-translate>Loading</strong>
            </div>
          </template>

          <!-- Empty state -->
          <template #empty="scope">
            <div class="w-100 mt-6 mb-6">
              <span class="d-flex align-items-center w-50 m-auto"
                ><span class="svg-icon svg-icon-3x mr-4 svg-icon-opalean-gray-light">
                  <inline-svg src="media/svg/icons/Code/Stop.svg" />
                </span>
                <span class="text-opalean-gray-light"
                  ><strong><translate>There are no operations to show</translate></strong
                  >, <translate>change filters or searchs params or wait for an operation...</translate></span
                >
              </span>
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="w-100 mt-6 mb-6">
              <span class="d-flex align-items-center w-50 m-auto"
                ><span class="svg-icon svg-icon-3x mr-4 svg-icon-opalean-gray-light">
                  <inline-svg src="media/svg/icons/Code/Stop.svg" />
                </span>
                <span class="text-opalean-gray-light"
                  ><strong><translate>There are no operations to show</translate></strong
                  >, <translate>change filters or searchs params or wait for an operation...</translate></span
                >
              </span>
            </div>
          </template>

          <!-- THead -->
          <template #thead-top="" v-if="showPagination">
            <b-tr>
              <b-th colspan="2" variant="opalean-white"
                ><div
                  class="border-right border-bottom-0 border-opalean-gray-light text-dark-75 pl-2"
                  v-show="filteredOperationsLength !== totalOperationsLength"
                >
                  <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase"><translate>Filtered</translate></label>
                  <span
                    ><span class="text-dark">{{ filteredOperationsLength }} <translate>operations</translate></span> on {{ totalOperationsLength }}</span
                  >
                </div>
                <div
                  class="border-right border-bottom-0 border-opalean-gray-light text-dark-75 pl-2"
                  v-show="filteredOperationsLength === totalOperationsLength"
                >
                  <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase"><translate>Total</translate></label>
                  <span>{{ totalOperationsLength }} <translate>operations</translate></span>
                </div>
              </b-th>
              <b-th variant="opalean-white"
                ><div class="border-right border-bottom-0 border-opalean-gray-light text-dark-75">
                  <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Period</label>
                  <span class="font-weight-normal"
                    ><translate>From</translate>&nbsp;
                    <span class="font-weight-bold">{{ StoredDatas.datas.filteredOperations.minDate | getDateFormat("LocaleDate") }}</span>
                    &nbsp;<translate>to</translate>&nbsp;
                    <span class="font-weight-bold">{{ StoredDatas.datas.filteredOperations.maxDate | getDateFormat("LocaleDate") }}</span></span
                  >
                </div></b-th
              >
              <b-th variant="opalean-white"
                ><div class="border-right border-bottom-0 border-opalean-gray-light text-dark-75">
                  <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Showing</label>
                  <span class="font-weight-normal"
                    ><span class="font-weight-bold">{{ perPage }}</span> <translate>operations per page</translate></span
                  >
                </div></b-th
              >
              <b-th variant="opalean-white"
                ><div class="text-dark-75">
                  <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Page</label>
                  <span class="font-weight-normal">{{ currentPage }}</span>
                </div></b-th
              >
            </b-tr>
          </template>

          <!-- Scoped slot for select state illustrative purposes / { rowSelected }-->
          <template #cell(selectedRow)="data">
            <div class="status h-100 w-100 position-absolute top-0 left-0"></div>
            <!-- <div class="d-none d-sm-flex flex-column justify-content-between h-100 w-100 position-absolute top-0 left-0">
              <! -- A -- >
              <div
                class="p-2 --h-50 text-center d-flex align-items-end justify-content-center"
                :class="showDetails ? 'h-50 align-items-end' : 'h-100 align-items-center'"
              >
                <label class="checkbox checkbox-lg checkbox-outline checkbox-outline-2x checkbox-primary mb-1">
                  <input type="checkbox" v-model="data.rowSelected" @change="toggleRow(data.index)" />
                  <span></span>
                  <span v-if="data.rowSelected" class="sr-only" v-translate>Selected</span>
                </label>
              </div>

              <! -- B -- >
              <div class="h-50 p-2 text-center d-flex align-items-start justify-content-center" v-if="showDetails">
                <b-button variant="transparent" size="sm" @click="data.toggleDetails">
                  <span v-if="!data.detailsShowing" class="svg-icon svg-icon-lg svg-icon-opalean-gray-medium">
                    <inline-svg src="media/svg/icons/General/Other1.svg"></inline-svg>
                  </span>
                  <span v-if="data.detailsShowing" class="svg-icon svg-icon-lg svg-icon-opalean-gray-medium">
                    <inline-svg src="media/svg/icons/Navigation/Minus.svg"></inline-svg>
                  </span>
                </b-button>
              </div>
            </div> -->
          </template>

          <!-- Row details / Timeline slot -->
          <template #row-details="data">
            <b-card>
              <div class="timeline timeline-2">
                <div class="timeline-bar"></div>
                <div v-for="timeline in data.item.operationTimeline" :key="timeline.timelineId">
                  <div class="timeline-item">
                    <span v-if="timeline.timelineType != null" class="timeline-badge" :class="`bg-${getTimelineType(timeline.timelineType, 'class')}`"></span>
                    <span v-else class="timeline-badge bg-secondary"></span>
                    <span class="timeline-content d-flex align-items-center justify-content-between">
                      <span class="mr-3">
                        <b-link v-if="timeline.timelineType != null" href="#" :class="`text-${getTimelineType(timeline.timelineType, 'class')}`">
                          {{ timeline.timelineMessage.replace("%s", timeline.timelineAffectedto) }}</b-link
                        >
                        <b-link v-else href="#" class="text-secondary"> {{ timeline.timelineMessage }}</b-link>
                        &nbsp;
                        <b-badge
                          pill
                          v-if="timeline.timelineStatusType != null"
                          :variant="getStatusType(timeline.timelineStatusType, 'class')"
                          class="font-weight-bolder font-size-sm align-middle text-white"
                          >{{ getStatusType(timeline.timelineStatusType, "name") }}</b-badge
                        >
                      </span>
                      <span class="text-muted text-right">{{ timeline.timelineTimestamp | getDateFormat("Humanized") }} <translate>ago</translate></span>
                    </span>
                  </div>
                </div>
              </div>
            </b-card>
          </template>

          <!-- Operation slot -->
          <template #cell(operationRow)="data">
            <span class="label label-lg label-outline-opalean-gray-medium label-inline font-weight-bolder rounded-right-0 border-width-2">{{
              data.item.OperationDate | getDateFormat("LocaleDate")
            }}</span>
            <span class="label label-lg label-opalean-gray-medium label-inline font-weight-bold rounded-left-0 border-width-2">{{
              data.item.OperationID | safePrint
            }}</span>

            <!-- Charter : isOS -->
            <span class="ml-2 mt-2">
              <span
                class="label label-lg label-inline font-weight-bold pl-1 pr-2 label-opalean-tertiary align-middle"
                v-if="data.item.isOS !== undefined && data.item.isOS === true"
              >
                <span class="svg-icon svg-icon-md svg-icon-dark mr-1">
                  <!--begin::Svg Icon -->
                  <inline-svg src="media/opalean/svg/Charter.svg" style="width: 20px !important"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
                {{ $gettext("Charter") }}</span
              >
            </span>

            <span v-if="data.item.OperationCMR" class="d-block text-opalean-primary font-weight-bolder mt-2"
              ><i class="far fa-sticky-note icon-nm text-opalean-primary"></i> {{ data.item.OperationCMR | safePrint }}</span
            >
            <div class="mt-0 mb-1">
              <span
                class="label label-sm label-inline label-square font-weight-bold text-opalean-gray-medium px-2 mr-1 h-auto lh-1 label-outline-secondary"
                v-for="(key, index) in data.item.Manifest"
                :key="index"
                :title="key.Type"
                >{{ key.Value | safePrint }}</span
              >
            </div>
            <!-- Messages & Docs -->
            <span v-if="data.item.HasNewMessages" class="--d-block text-opalean-primary font-weight-bolder mt-2 mr-2"
              ><i class="far fa-comment-alt icon-nm text-opalean-gray-medium"></i
            ></span>
            <span v-if="data.item.HasMyDoc" class="--d-block text-opalean-primary font-weight-bolder mt-2 mr-2 ml-n1"
              ><i class="la la-paperclip icon-md text-opalean-primary"></i
            ></span>
            <span v-if="data.item.HasOtherDoc" class="--d-block text-opalean-primary font-weight-bolder mt-2 mr-2 ml-n1"
              ><i class="la la-paperclip icon-md text-warning"></i
            ></span>
          </template>

          <!-- Flow slot -->
          <template #cell(flowRow)="data">
            <code v-if="showDataDebug"
              >MyRole:: {{ data.item.MyRole }} HisRole:: {{ data.item.HisRole }} OperationStatus:: {{ data.item.OperationStatus }} isOS::
              {{ data.item.isOS }}</code
            >

            <DetailsPanel
              v-if="data.item.OperationType !== null && typeof data.item.OperationType === 'string'"
              :svgIconSrc="getFlowType(data.item.OperationType, 'icon')"
              :inlineIconSrc="
                getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'isClient', null)
                  ? 'flaticon2-correct'
                  : null
              "
              :title="getFlowType(data.item.OperationType, 'name')"
              :titleDescription="getFlowType(data.item.OperationType, 'description')"
              :subtitle="
                getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'Name', '—', data.item.HisRole)
              "
              :subtitleDescription="
                getOperationThirdPartyDetails(
                  data.item.OperationType,
                  data.item.MyRole,
                  data.item.Partners,
                  'thirdparty',
                  'ZipCode,City,CountryISO,District',
                  '—',
                  data.item.HisRole
                )
              "
              :inverted="false"
            >
            </DetailsPanel>
          </template>

          <!-- Flow slot -->
          <template #cell(thirdpartyRow)="data">
            <!-- ThirdParties -->
            <ThirdPartiesPanel
              :MyRole="data.item.MyRole"
              :CounterPartRole="getAllowedThirdPartyType(data.item.OperationType, data.item.MyRole, 'thirdparty', data.item.HisRole)[0]"
              :shipperName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'shipper', 'Name')"
              :carrierName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'carrier', 'Name')"
              :dockName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'dock', 'Name')"
              :receiverName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'receiver', 'Name')"
              :notifiedName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'notified', 'Name')"
              :subShipperName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'subshipper', 'Name')"
              :subCarrierName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'subcarrier', 'Name')"
              :subReceiverName="getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'subreceiver', 'Name')"
            ></ThirdPartiesPanel>

            <!-- Pallets-->
            <b-table-lite
              id="thirdparty-table"
              class="table-opalean-gray-light table-bordered table-vertical-center mt-1 mb-0"
              small
              thead-class="d-none"
              :items="getOperationPOVDetails(data.item.OperationType, data.item.MyRole, data.item.POVs, 'all', 'Pallets')[0]"
              :fields="palletFields"
              :tbody-tr-class="palletRowClass"
            >
              <!-- Icon slot -->
              <template #cell(iconRow)="d">
                <b-skeleton-wrapper v-if="d.item.PalletID != undefined" :loading="Pallets.length == 0">
                  <template #loading>
                    <b-skeleton animation="fade" width="80%"></b-skeleton>
                  </template>
                  <span
                    v-if="typeof getPalletType(getPalletValue(d.item.PalletID, 'PalletType'), 'class') !== 'undefined'"
                    class="svg-icon svg-icon-md"
                    :class="getPalletType(getPalletValue(d.item.PalletID, 'PalletType'), 'class')"
                  >
                    <inline-svg :src="getPalletType(getPalletValue(d.item.PalletID, 'PalletType'), 'icon')"></inline-svg
                  ></span>
                </b-skeleton-wrapper>
              </template>

              <!-- Pallet slot -->
              <template #cell(palletRow)="d">
                <span class="font-weight-normal">{{ getPalletValue(d.item.PalletID, "PalletName") }}</span>
              </template>

              <!-- Accounts slots -->
              <!--  https://forum.vuejs.org/t/how-to-pass-cell-templates-to-a-component-with-b-table/106283/2 / #cell(toCellName(slot,index))="data" -->
              <template #cell(accountRow1)="d">
                <span
                  class="font-size-h6"
                  v-if="data.item.OperationType !== 'T'"
                  v-b-tooltip.hover.html="getAccountType(data.item.OperationType === 'D' ? 1 : 0, d.item.FP.toLocaleString(), 'name')"
                  v-html="getAccountType(data.item.OperationType === 'D' ? 1 : 0, d.item.FP.toLocaleString(), 'shortName')"
                >
                </span>
                <span
                  class="font-size-h6"
                  v-else
                  v-b-tooltip.hover.html="getAccountType(3, d.item.FP.toLocaleString(), 'name')"
                  v-html="getAccountType(3, d.item.FP.toLocaleString(), 'shortName')"
                >
                </span>
              </template>
              <template #cell(accountRow2)="d">
                <span
                  class="font-size-h6"
                  v-if="data.item.OperationType !== 'T' && !['P', 'L'].includes(getPalletValue(d.item.PalletID, 'PalletType'))"
                  v-b-tooltip.hover.html="getAccountType(2, d.item.FR.toLocaleString(), 'name')"
                  v-html="getAccountType(2, d.item.FR.toLocaleString(), 'shortName')"
                >
                </span>
              </template>
              <template #cell(accountRow3)="d">
                <span
                  class="font-size-h6"
                  v-if="!['P', 'L'].includes(getPalletValue(d.item.PalletID, 'PalletType'))"
                  :class="
                    getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR) < 0 ? 'text-info' : 'text-opalean-info'
                  "
                  v-b-tooltip.hover.html="
                    getAccountType(
                      4,
                      (getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR) <= 0 ? '' : '+') +
                        getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR).toLocaleString(),
                      'name'
                    )
                  "
                  v-html="
                    getAccountType(
                      4,
                      (getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR) <= 0 ? '' : '+') +
                        getImpactAccount(data.item.OperationType, data.item.MyRole, data.item.HisRole, d.item.FP, d.item.FR).toLocaleString(),
                      'shortName'
                    )
                  "
                >
                </span>
              </template>
              <!-- <template #cell(transfertRow1)="d">
                <span
                  class="font-size-h6"
                  v-if="data.item.OperationType === 'T'"
                  v-b-tooltip.hover.html="getAccountType(3, d.item.FP, 'name')"
                  v-html="getAccountType(3, d.item.FP, 'shortName')"
                >
                </span>
              </template>-->
            </b-table-lite>
          </template>

          <!-- Scoped slot for select state illustrative purposes -->
          <template #cell(actionRow)="data">
            <div class="d-flex flex-column justify-content-between h-100 w-100 --position-absolute --top-0 --left-0" style="width: 50px">
              <!-- A -->
              <div class="pt-5 p-4 h-50 text-center d-flex align-items-start justify-content-end">
                <b-badge
                  pill
                  style="width: 150px"
                  v-if="
                    getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'Attributes').includes(
                      'isClient'
                    ) && getStatusType(data.item.OperationStatus, 'id') !== 'R'
                  "
                  :variant="getStatusType(data.item.OperationStatus, 'class')"
                  class="font-weight-bolder font-size-lg text-white OperationStatus"
                  >{{ $gettext(getStatusType(data.item.OperationStatus, "name")) }}</b-badge
                >

                <i
                  v-else-if="
                    !getOperationThirdPartyDetails(data.item.OperationType, data.item.MyRole, data.item.Partners, 'thirdparty', 'Attributes').includes(
                      'isClient'
                    )
                  "
                ></i>
                <i v-else class="text-opalean-primary icon-md flaticon2-correct pl-0 pr-5"></i>
                <!-- Do not print R badge for isNotClient counterpart -->
              </div>
              <!-- B -->
              <div class="py-2 px-4 h-50 text-center d-flex align-items-end justify-content-end">
                <b-button-group size="" class="">
                  <!-- Validate -->
                  <b-button
                    v-if="getStatusType(data.item.OperationStatus, 'actions').includes('validate')"
                    @click="validateOperation(data.item.OperationUID)"
                    variant="hover-light-bg-success"
                    class="pl-0 pr-2 py-3 font-weight-bold btn-hover-text-success btn-hover-icon-success"
                    v-b-tooltip.hover
                    :title="$gettext('Validate all statements')"
                    ><i class="la la-check-circle icon-xl"></i
                  ></b-button>
                  <!-- Reject -->
                  <b-button
                    v-if="getStatusType(data.item.OperationStatus, 'actions').includes('reject')"
                    :to="{
                      name: 'route.operations.edit',
                      params: {
                        OperationUID: data.item.OperationUID,
                        Validate: false,
                      },
                    }"
                    variant="hover-light-bg-danger"
                    class="pl-0 pr-2 py-3 font-weight-bold btn-hover-text-danger btn-hover-icon-danger"
                    v-b-tooltip.hover
                    :title="$gettext('Reject all or some statement(s)')"
                    ><i class="la la-exclamation-circle icon-xl"></i
                  ></b-button>
                  <!-- Create -->
                  <b-button
                    v-if="getStatusType(data.item.OperationStatus, 'actions').includes('create')"
                    :to="{
                      name: 'route.operations.edit',
                      params: {
                        OperationUID: 0,
                        isOS: data.item.isOS,
                      },
                    }"
                    variant="hover-light-bg-success"
                    class="px-2 py-3 font-weight-bold btn-hover-text-dark btn-hover-icon-success"
                    v-b-tooltip.hover
                    :title="$gettext('Create operation')"
                    ><i class="la la-plus icon-xl"></i
                  ></b-button>
                  <!-- Edit -->
                  <b-button
                    v-if="getStatusType(data.item.OperationStatus, 'actions').includes('edit')"
                    :to="{
                      name: 'route.operations.edit',
                      params: {
                        OperationUID: data.item.OperationUID,
                        isOS: data.item.isOS,
                      },
                    }"
                    variant="hover-light-bg-secondary"
                    class="px-2 py-3 font-weight-bold btn-hover-text-dark btn-hover-icon-dark"
                    v-b-tooltip.hover
                    :title="$gettext('Edit operation')"
                    ><i class="la la-edit icon-xl"></i
                  ></b-button>
                  <!-- Add document -->
                  <!-- <b-button
                    v-if="getStatusType(data.item.OperationStatus, 'actions').includes('adddocument')"
                    :to="{
                      name: 'route.operations.edit',
                      params: {
                        OperationUID: data.item.OperationUID,
                        AddDocument: true,
                      },
                    }"
                    variant="hover-light-bg-opalean-info"
                    class="pl-2 pr-0 py-3 font-weight-bold btn-hover-text-opalean-info btn-hover-icon-opalean-info"
                    v-b-tooltip.hover
                    :title="$gettext('Add document')"
                    ><i class="la la-paperclip icon-xl"></i
                  ></b-button> -->
                  <!-- Delete -->
                  <b-button
                    v-if="getStatusType(data.item.OperationStatus, 'actions').includes('delete')"
                    variant="hover-light-bg-danger"
                    class="pl-2 pr-0 py-3 font-weight-bold btn-hover-text-danger btn-hover-icon-danger"
                    v-b-tooltip.hover
                    :title="$gettext('Delete operation')"
                    ><i class="la la-trash icon-xl"></i
                  ></b-button>
                  <!-- Misc actions -->
                  <b-dropdown
                    v-if="getStatusType(data.item.OperationStatus, 'actions').includes('misc')"
                    variant="link"
                    class="p-0"
                    toggle-class="px-2 py-3 text-decoration-none btn-hover-text-white btn-hover-icon-dark"
                    no-caret
                    dropleft
                  >
                    <template #button-content>
                      <i class="la la-ellipsis-h icon-xl"></i>
                    </template>
                    <b-dropdown-header id="dropdown-header-1" v-translate>Groups</b-dropdown-header>
                    <b-dropdown-item v-translate>Duplicate</b-dropdown-item>
                    <b-dropdown-item v-translate>Change status</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-header id="dropdown-header-1" v-translate>Groups</b-dropdown-header>
                    <b-dropdown-item v-translate>Export</b-dropdown-item>
                    <b-dropdown-item v-translate>Print</b-dropdown-item>
                  </b-dropdown>
                </b-button-group>
              </div>
            </div>
          </template>
        </b-table>
        <!--end::Table-->
      </div>
      <!--end::Body-->

      <!--end::Footer-->
      <div class="card-footer border-0 p-5 bg-opalean-white --border-0 pl-5 pr-5" v-if="showPagination">
        <b-container fluid class="p-0">
          <!-- User Interface controls -->
          <b-row class="align-items-center">
            <b-col class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark">
              <label class="mr-4 d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Bulk actions</label>
              <b-button-group>
                <b-button variant="outline-secondary" size="sm" @click="selectAllRows" v-translate>Select all</b-button>
                <b-button variant="outline-secondary" size="sm" @click="clearSelected" v-translate>Clear selected</b-button>
              </b-button-group>
            </b-col>

            <b-col
              class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
              v-if="filteredOperationsLength !== totalOperationsLength"
            >
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Filtered</label>
              <span class="font-weight-bolder">{{ filteredOperationsLength }} <translate>operations</translate></span>
            </b-col>

            <b-col class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75">
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Total</label>
              <span class="font-weight-bolder">{{ totalOperationsLength }} <translate>operations</translate></span>
            </b-col>

            <b-col
              v-if="this.showPagination == true || this.showPagination == null"
              class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
            >
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Showing</label>
              <b-form inline>
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" :value="perPage" size="sm" class="w-25"></b-form-select>
                <label class="ml-sm-1" for="per-page-select" v-translate>operations per page</label>
              </b-form>
            </b-col>

            <b-col class="d-flex flex-column justify-content-center --align-items-center border-right-0 border-bottom-0 border-opalean-gray-light text-dark-75">
              <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Page</label>
              <b-pagination
                v-if="filteredOperationsLength > 0"
                v-model="currentPage"
                :total-rows="filteredOperationsLength"
                :per-page="perPage"
                aria-controls="list-table"
                first-number
                last-number
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!--end::Footer-->
    </div>
    <!--end::ListOperations -->
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters, mapActions } from "vuex";
import DetailsPanel from "@/view/content/neoopatrace/components/DetailsPanel.vue";
import ThirdPartiesPanel from "@/view/content/neoopatrace/components/ThirdPartiesPanel.vue";
import Multiselect from "vue-multiselect";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

import ApiService from "@/core/services/api.service";
//import Swal from "sweetalert2";
import * as moment from "moment";

export default {
  name: "operations-list-component",
  components: { DetailsPanel, ThirdPartiesPanel, Multiselect },
  props: ["itemsLimit", "itemsPerPage", "itemsOrder", "showSearch", "showFilters", "showCreateButton", "showPagination", "showHeader"],
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,
      //
      perPage: 20,
      currentPage: 0,
      isBusy: true,
      isBulkVisible: false,
      pageOptions: [5, 10, 20, 30, 50, 100, 1000, 2000, { value: 10000, text: this.$gettext("Show a maximum") }],
      filter: "",
      filterOn: [],
      filterChartersClaim: true, // v6
      filteredOperationsLength: 0, // b-pagination fix ! https://github.com/bootstrap-vue/bootstrap-vue/issues/1629
      fields: [
        //
        {
          key: "selectedRow",
          label: "",
          tdClass: "text-left p-0 --position-relative--see-scss --w-50-px w-0-px w-100-xs --d-none --d-sm-table-cell", // Hack for borders : border-collapse ? // d-none d-sm-table-cell borders FAILS
          stickyColumn: false, // Warning : add a background-image and break all hover / striper / selected styles
        },
        {
          key: "operationRow",
          label: this.$gettext("Operation"),
          tdClass: "text-left",
          stickyColumn: false, // Warning : add a background-image and break all hover / striper / selected styles
        },
        {
          key: "flowRow",
          label: this.$gettext("Flow"),
          tdClass: "text-left",
          stickyColumn: false, // Warning : add a background-image and break all hover / striper / selected styles
        },
        {
          key: "thirdpartyRow",
          label: this.$gettext("Thirdparties & accounts"),
          tdClass: "text-left",
        },
        {
          key: "actionRow",
          label: this.$gettext("Status & actions"),
          tdClass: "text-left p-0 position-relative w-200-px w-100-xs", // Hack for borders : border-collapse ?
        },
      ],
      palletFields: [
        {
          key: "iconRow",
          label: "",
          tdClass: "text-left w-5",
        },
        {
          key: "palletRow",
          label: this.$gettext("Pallets"),
          tdClass: "w-45",
        },
        {
          key: "accountRow1",
          label: this.$gettext("Credits"),
          tdClass: "text-right w-10",
        },
        {
          key: "accountRow2",
          label: this.$gettext("Debts"),
          tdClass: "text-right w-10",
        },
        {
          key: "accountRow3",
          label: this.$gettext("Balance"),
          tdClass: "text-right w-10",
        },
        /*{
          key: "transfertRow1",
          label: this.$gettext("Transferts"),
          tdClass: "text-right w-10"
        }*/
      ],
      Operations: [],
      selected: [],
      selectVal: {
        OperationType: undefined,
        MyRole: undefined,
        OperationStatus: undefined,
      },
      // @imported from Statics
      OperationType: statics.flowTypes, // Helpers for filter select options
      MyRole: statics.thirdPartyTypes, // Helpers for filter select options
      OperationStatus: statics.statusTypes, // Helpers for filter select options // WARNING > Don't had s
      //
      timelineTypes: statics.timelineTypes,
      palletTypes: statics.palletTypes,
      accountTypes: statics.accountTypes,
      filterLabels: statics.filterLabels,
      // Search
      searchCMR: "",
      searchPartner: "",
      searchFromDate: "",
      searchToDate: "",
      searchLimit: "",
      searchStatus: "",
      runCallBySearch: false,
      // Details
      showDetails: false,
      // Search filter Partners
      searchedPartners: null,
    };
  },
  beforeMount() {
    console.log("beforeMount:: FilteredOperations::", this.FilteredOperations, this.FilteredOperations.PerPage, this.itemsPerPage);
    // Current page
    if (this.FilteredOperations.CurrentPage != null) this.currentPage = Number(this.FilteredOperations.CurrentPage);
    else this.currentPage = 1;

    // Per page
    if (this.FilteredOperations.PerPage != null) this.perPage = Number(this.FilteredOperations.PerPage);
    else this.perPage = 20;

    // FILTERS fill stored fields
    // Filter
    if (this.FilteredOperations.Filter != null && this.FilteredOperations.Filter !== "") this.filter = String(this.FilteredOperations.Filter);

    // FilterOn
    if (this.FilteredOperations.FilterOn != null && this.FilteredOperations.FilterOn.length > 0) this.filterOn = this.FilteredOperations.FilterOn;

    // Selected
    if (this.FilteredOperations.Selected != null && typeof this.FilteredOperations.Selected == "object")
      this.selectVal = Object(this.FilteredOperations.Selected);
  },
  created() {
    console.log("Created::", typeof this.FilteredOperations);

    /* Created from a route */
    if (typeof this.$route.params.PartnerID !== "undefined") {
      // Used to filter by OperationStatus
      if (typeof this.$route.params.runCallBySearch === "undefined" || this.$route.params.runCallBySearch === false)
        this.filter = this.$route.params.PartnerID.toString();
      // Used to filter by PartnerID
      else
        this.searchPartner =
          this.$route.params.PartnerID != 0 && typeof this.getPartnerByPartnerID(this.$route.params.PartnerID) !== "undefined"
            ? this.getPartnerByPartnerID(this.$route.params.PartnerID)
            : {}; // Used to search by PartnerID
    }
    if (typeof this.$route.params.PartnerName !== "undefined") this.filter = this.$route.params.PartnerName; // Used to filter by PartnerName
    if (typeof this.$route.params.Status !== "undefined") {
      // Used to filter by OperationStatus
      if (typeof this.$route.params.runCallBySearch === "undefined" || this.$route.params.runCallBySearch === false)
        this.selectVal.OperationStatus = this.$route.params.Status;
      // Used to filter by Status
      else this.searchStatus = this.$route.params.Status; // Used to search by Status
    }
    if (typeof this.$route.params.runCallBySearch !== "undefined" && this.$route.params.runCallBySearch === true) {
      // Used to define if scope list by filter or search : Default filter
      this.runCallBySearch = this.$route.params.runCallBySearch;
      this.searchLimit = 9999;
    }

    // SEARCHES fill stored fields
    // searchPartner
    console.log("Created::", this.FilteredOperations);
    if (this.FilteredOperations.searchPartner != null && typeof this.FilteredOperations.searchPartner == "object")
      this.searchPartner = Object(this.FilteredOperations.searchPartner);

    // searchFromDate
    if (this.FilteredOperations.searchFromDate != null && this.FilteredOperations.searchFromDate !== "")
      this.searchFromDate = String(this.FilteredOperations.searchFromDate);

    // searchToDate
    if (this.FilteredOperations.searchToDate != null && this.FilteredOperations.searchToDate !== "")
      this.searchToDate = String(this.FilteredOperations.searchToDate);

    // searchLimit
    if (this.FilteredOperations.searchLimit != null && this.FilteredOperations.searchLimit !== "")
      this.searchLimit = String(this.FilteredOperations.searchLimit);

    // searchStatus
    if (this.FilteredOperations.searchStatus != null && this.FilteredOperations.searchStatus !== "")
      this.searchStatus = String(this.FilteredOperations.searchStatus);

    // https://jerickson.net/created-vs-mounted-in-vue/
    // First run call ( with a status search if needed )
    // this.runCall(
    //   this.runCallBySearch === true ? 9999 : undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   this.runCallBySearch === true ? this.searchStatus : undefined
    // );
    this.runCall(
      this.searchLimit !== "" || this.runCallBySearch === true ? this.searchLimit : undefined,
      this.searchPartner !== "" ? this.searchPartner : undefined,
      this.searchFromDate !== "" ? this.searchFromDate : undefined,
      this.searchToDate !== "" ? this.searchToDate : undefined,
      this.searchStatus !== "" || this.runCallBySearch === true ? this.searchStatus : undefined
    );
  },
  mixins: [helpers],
  methods: {
    validateDate(date) {
      // Regex pour valider le format DD/MM/YYYY
      const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;

      if (!regex.test(date)) {
        return false;
      }

      // Vérifier si la date est valide
      const parts = date.split("/");
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      const dateObj = new Date(year, month, day);

      return dateObj.getDate() === day && dateObj.getMonth() === month && dateObj.getFullYear() === year;
    },
    onChangeDate(date, field) {
      if (this.validateDate(date)) {
        const parts = date.split("/");
        this[field] = `${parts[2]}-${parts[1]}-${parts[0]}`; // Format YYYY-MM-DD pour v-model
        switch (field) {
          case "searchToDate":
            if (this.searchFromDate == "") {
              this.searchFromDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            }
            break;
          case "searchFromDate":
            if (this.searchToDate == "") {
              this.searchToDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            }
            break;
          default:
            break;
        }
      } else {
        // Réinitialiser le champ si la date est invalide
        this[field] = "";
        // Optionnel : afficher un message d'erreur
        // this.$bvToast.toast("Date invalide. Utilisez le format DD/MM/YYYY.", {
        //   title: "Erreur",
        //   variant: "danger",
        //   solid: true,
        // });
      }
    },
    onDateContext(ctx, field) {
      this[field] = ctx.selectedYMD;
      switch (field) {
        case "searchToDate":
          if (this.searchFromDate == "") {
            this.searchFromDate = ctx.selectedYMD;
          }
          break;
        case "searchFromDate":
          if (this.searchToDate == "") {
            this.searchToDate = ctx.selectedYMD;
          }
          break;
        default:
          break;
      }
    },
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      // if (item.operationHighlighted === true) return "table-success"; // Not needed anymore
      if (item.OperationStatus !== null) return "status-" + item.OperationStatus;
    },
    palletRowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.OperationType === "T") return "text-info";
    },
    // Table events
    onFiltered(filteredItems) {
      console.log("onFiltered::");
      // Then store Operations list IDs and Filters to store
      this.callFilteredOperations(filteredItems);
    },
    onTableChanged(ctx) {
      console.log("onTableChanged::", ctx, ctx.currentPage, ctx.perPage);
      // Then update store
      if (ctx.currentPage !== undefined)
        this.updateFilteredOperations({
          CurrentPage: this.currentPage,
        });
      if (ctx.perPage !== undefined)
        this.updateFilteredOperations({
          PerPage: this.perPage,
        });
    },
    //
    haveAnyFilters() {
      return (this.filter == undefined || this.filter == "") && this.filterOn.length == 0 && Object.keys(this.selectVal).every((k) => !this.selectVal[k])
        ? true
        : false;
    },
    clearAllFilters(e) {
      e.preventDefault();

      if (this.clearTimeout) {
        clearTimeout(this.clearTimeout);
      }

      this.clearTimeout = setTimeout(() => {
        this.filter = "";
        this.filterOn = [];
        this.selectVal = Object.fromEntries(Object.keys(this.selectVal).map((key) => [key, undefined]));

        this.clearTimeout = null;
      }, 100);
    },
    haveAnySearchs() {
      return this.searchPartner == "" && this.searchFromDate == "" && this.searchToDate == "" && this.searchLimit == "" && this.searchStatus == "";
    },
    haveAnyStoredSearchs() {
      return this.FilteredOperations.searchPartner == "" &&
        this.FilteredOperations.searchFromDate == "" &&
        this.FilteredOperations.searchToDate == "" &&
        this.FilteredOperations.searchLimit == "" &&
        this.FilteredOperations.searchStatus == ""
        ? true
        : false;
    },
    clearAllSearchs(e) {
      e.preventDefault();

      if (this.clearSearchTimeout) {
        clearTimeout(this.clearSearchTimeout);
      }

      this.clearSearchTimeout = setTimeout(() => {
        // Réinitialiser tous les champs de recherche
        const searchFields = ["searchPartner", "searchFromDate", "searchToDate", "searchLimit", "searchStatus", "searchCMR"];
        searchFields.forEach((field) => (this[field] = ""));

        // Re-run to clear
        this.runSearchs();

        this.clearSearchTimeout = null;
      }, 100);
    },
    runSearchs() {
      this.runCall(this.searchLimit, this.searchPartner, this.searchFromDate, this.searchToDate, this.searchStatus, this.searchCMR);
      console.log("runSearchs::callFilteredOperations");
      // Watch and store Operations list Searches to store
      this.callFilteredOperations();
    },
    // onRowSelected(Operations) {
    //   this.selected = Operations;
    //   if (Operations.length > 0) this.isBulkVisible = true;
    //   else this.isBulkVisible = false;
    // },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectRow(index) {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(index);
    },
    toggleRow(index) {
      if (this.$refs.selectableTable.isRowSelected(index) === true) {
        this.$refs.selectableTable.unselectRow(index);
      } else {
        this.$refs.selectableTable.selectRow(index);
      }
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    // Static helpers methods
    // Used when v-for and v-if needed : v-if="item.MainRole == role.id"
    // ** See helpers.js
    // Local helpers
    getPalletValue(id, value) {
      console.log("getPalletValue::", id, value);
      let _Pallet = [];
      if (typeof id !== "undefined" && typeof value !== "undefined") _Pallet = this.getPallet(id);
      if (typeof _Pallet !== "undefined") return _Pallet[value];
      else return [];
    },
    getOptionNamebyKeys(key, option, label = "name") {
      console.log("getOptionNamebyKeys::", key, option, label);
      // let _key = key + "s";
      //return this[_key][option - 1][label]; // Old v1
      // console.log("getOptionNamebyKeys::label", this[key].filter(f => f.id == option)[label]); // Returns undefined
      // console.log("getOptionNamebyKeys::label", this[key].filter(f => f.id == option)[0][label]); // Returns String
      // console.log("getOptionNamebyKeys::label",this[key].filter(f => f.id == option).map(f => f[label])); // Returns Array
      try {
        return this[key].filter((f) => f.id == option)[0][label];
      } catch (e) {
        // Log
        console.log("[ERROR]getOptionNamebyKeys::" + e, key, option, label);

        // Then, message
        window.swalAlert.fire({
          title: this.$gettext("Filters error"),
          html: this.$gettext("Please contact our support."),
          // timer: 1200,
          // timerProgressBar: true,
          confirmButtonClass: "btn btn-outline-secondary",
          // heightAuto: false,
          icon: "error",
          footer: `<code>GetOptionNamebyKeys | ${key} | ${option}</code>`,
        });

        // Then, unknown option name
        return "-";
      }
    },

    //API Calls
    async runCall(limit, partner, fromdate, todate, status, cmr) {
      console.log("runCall::", limit, partner, fromdate, todate, status);

      const params = new URLSearchParams({
        limit: limit || this.itemsLimit || this.getUserGetOperationsLimit,
        ...(partner && { partner: partner.PartnerID }),
        ...(fromdate && { fromdate }),
        ...(todate && { todate }),
        ...(status && { status }),
        ...(cmr && { cmr }),
      });

      try {
        const response = await ApiService.get("/operations", params.toString());
        console.log("/operations::", {
          userMainRole: this.getUserMainRole,
          itemsLimit: this.itemsLimit,
          response,
        });

        this.Operations = this.processOperations(response.data.Operations);
        this.enrichOperationsData();

        // Toggle busy state
        this.isBusy = false;
        // Update length
        this.filteredOperationsLength = this.Operations.length;

        this.updatePagination();
        this.updateFilters();
      } catch (error) {
        console.error("Error fetching operations:", error);
        this.isBusy = false;
      }
    },
    processOperations(operations) {
      if (this.itemsLimit && this.itemsLimit > 0) {
        operations = operations.slice(0, this.itemsLimit);
        if (this.itemsOrder === "DESC") operations.reverse();
      }
      return operations;
    },
    enrichOperationsData() {
      this.Operations.forEach((o) => {
        o._PartnerIDs = [];
        o._PartnerNames = [];
        o._PartnerReferences = [];
        o._PalletIDs = [];
        o._PalletNames = [];

        this.processPartners(o);
        this.processPallets(o);
        o._OperationDate = new Date(o.OperationDate).toLocaleDateString();
      });
    },
    processPartners(operation) {
      operation.Partners.forEach((p) => {
        if (p.PartnerID === 0 && p.PartnerFullName) {
          operation._PartnerNames.push(p.PartnerFullName);
        } else if (p.PartnerID !== 0) {
          const partner = this.getPartnerByPartnerID(p.PartnerID);
          if (partner) {
            operation._PartnerIDs.push(p.PartnerID);
            operation._PartnerNames.push(partner.Name, _.lowerCase(_.deburr(partner.Name)));
            operation._PartnerReferences.push(partner.Reference, _.lowerCase(_.deburr(partner.Reference)));
          }
        }
      });
    },
    processPallets(operation) {
      operation.POVs.forEach((pov) => {
        pov.Pallets.forEach((pallet) => {
          if (pallet.PalletID !== 0) {
            const palletInfo = this.getPallet(pallet.PalletID);
            if (palletInfo) {
              operation._PalletIDs.push(pallet.PalletID);
              operation._PalletNames.push(palletInfo.PalletName);
            }
          }
        });
      });
    },
    updatePagination() {
      if (this.itemsPerPage !== null && this.FilteredOperations.PerPage == null) {
        this.perPage = this.itemsPerPage;
      }
      // if (this.FilteredOperations.PerPage != null) this.perPage = Number(this.FilteredOperations.PerPage);
      // if (this.FilteredOperations.CurrentPage != null) this.currentPage = Number(this.FilteredOperations.CurrentPage);
      // else this.currentPage = 1;
    },
    updateFilters() {
      this.filterChartersClaim = this.getUserMainRole === "TR";
    },

    validateOperation(OperationUID) {
      console.log("validateOperation::", OperationUID);

      // Const
      this.isValidating = true;
      let actionTitle = this.$gettext("Validate");
      let actionMessage = this.$gettext("validated");

      // Validate Operation
      (async () => {
        let response = null;
        let errorStatus = null;
        let errorData = null;
        try {
          response = await ApiService.get("/AgreePartnerPOV" + (typeof OperationUID !== "undefined" ? "?ID=" + OperationUID : ""));
        } catch (error) {
          console.error("validateOperation:: Error response:");
          console.error(error.response.data); // ***
          console.error(error.response.status); // ***
          console.error(error.response.headers); // ***

          errorStatus = error.response.status;
          errorData = error.response.data.errors.join(", ");
        } finally {
          console.log(response);
          console.log(errorStatus);
          this.isValidating = false;

          if (response === null && errorStatus !== null) {
            // Error
            window.swalAlert.fire({
              title: errorStatus,
              html: this.$gettext("Damned, we are sorry, we have an error...") + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              footer: '<a href="mailto:support@opalean.fr">' + this.$gettext("Need to contact support ?") + "</a>",
            });
          } else if (response !== null && response.status === 200) {
            console.info("validateOperation:: Success response:", response);

            // Success
            window.swalToast.fire({
              title: actionTitle + " " + this.$gettext("Operation"),
              html: this.$gettext("The operation has been successfully") + " <em>" + actionMessage + "</em> !",
              icon: "success",
              timer: 2000,
              confirmButtonClass: "btn btn-secondary",
              footer: this.$gettext("Operation list will be automatically reloaded in few seconds..."),
            });

            // Reload if OperationID
            setTimeout(() => {
              console.log("validateOperation:: Success, then reload...");
              //this.runCall(); // Fix loosing search filters
              /*this.runCall(
                this.runCallBySearch === true ? 9999 : undefined,
                undefined,
                undefined,
                undefined,
                this.runCallBySearch === true ? this.searchStatus : undefined
              );*/
              this.runCall(
                this.searchLimit !== "" || this.runCallBySearch === true ? this.searchLimit : undefined,
                this.searchPartner !== "" ? this.searchPartner : undefined,
                this.searchFromDate !== "" ? this.searchFromDate : undefined,
                this.searchToDate !== "" ? this.searchToDate : undefined,
                this.searchStatus !== "" || this.runCallBySearch === true ? this.searchStatus : undefined
              );
            }, 2000);
          }
        }
      })();
    },

    // Update filtered Operations to store IDs and filter when editing
    ...mapActions(["updateFilteredOperations"]),
    callFilteredOperations(filteredItems = this.filteredItems) {
      console.log("updateFilteredOperations::", this.filteredItems.length, filteredItems);

      // Store updated items length & trigger pagination to update the number of buttons/pages due to filtering
      this.filteredOperationsLength = filteredItems.length;
      // this.currentPage = 1;

      // Then update store ( only if loaded )
      if (this.filteredItems.length > 0) {
        const operationDates = filteredItems.filter((o) => o.OperationDate !== null).map((o) => new Date(o.OperationDate));
        this.updateFilteredOperations({
          //IDs: filteredItems.map((o) => o.OperationID), // V4
          IDs: filteredItems.map((o) => o.OperationUID), // V6
          /* IDs: filteredItems.map((o) => {
            return { OperationID: o.OperationID, OperationType: o.OperationType, isOS: o.isOS };
          }), // V6 */
          // Filters
          maxDate: new Date(Math.max(...operationDates)),
          minDate: new Date(Math.min(...operationDates)),
          Filter: this.filter,
          FilterOn: this.filterOn,
          Selected: this.selectVal,
          //CurrentPage: this.currentPage, // Do not update here, because function is called before runCall
          //PerPage: this.perPage, // Do not update here, because function is called before runCall

          // Searchs
          searchPartner: this.searchPartner,
          searchFromDate: this.searchFromDate,
          searchToDate: this.searchToDate,
          searchLimit: this.searchLimit,
          searchStatus: this.searchStatus,
        });
      }
    },

    // Fix Multiselect search without Diacritics ( accents )
    /* inspired by https://github.com/shentao/vue-multiselect/issues/281#issuecomment-597718858 */
    /* inside map, this refer to Window and not App */
    /* https://stackoverflow.com/questions/46371302/map-unable-to-access-objects-this-function */
    ignoreAccents(search) {
      const needle = _.lowerCase(_.deburr(search));

      console.log(
        "ignoreAccents::",
        search,
        search.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        needle,
        this.Partners.filter(
          (p) =>
            _.lowerCase(_.deburr(p.Name)).includes(needle) ||
            _.lowerCase(_.deburr(p.City)).includes(needle) ||
            _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
            _.lowerCase(_.deburr(p.Reference)).includes(needle) ||
            (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : "")
        )
      );

      this.searchedPartners = search
        ? this.Partners.filter(
            (p) =>
              _.lowerCase(_.deburr(p.Name)).includes(needle) ||
              _.lowerCase(_.deburr(p.City)).includes(needle) ||
              _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
              _.lowerCase(_.deburr(p.Reference)).includes(needle) ||
              (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : "")
          )
        : this.Partners;
    },
  },
  watch: {
    filteredItems: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (newItems) {
        console.log("Watch::filteredItems length=" + newItems.length);
        // Watch and store Operations list IDs and Filters to store
        this.callFilteredOperations();
      },
    },

    currentPage: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (o, n) {
        console.log("Watch::currentPage", o, n, this.FilteredOperations.CurrentPage);
      },
    },
  },
  computed: {
    isValidSearchFilter() {
      return !(this.searchPartner == "" && this.searchLimit == "" && this.searchStatus == "" && (this.searchFromDate == "" || this.searchToDate == ""));
    },
    isDateValid() {
      return (this.searchFromDate == "" && this.searchToDate == "") || (this.isSearchFromDate && this.isSearchToDate);
    },
    isSearchFromDate() {
      return this.validateDate(this.formatDate(this.searchFromDate));
    },
    isSearchToDate() {
      return this.validateDate(this.formatDate(this.searchToDate));
    },
    ...mapGetters([
      "getPartnersFor",
      "getPartnerByPartnerID",
      "getPallets",
      "getPallet",
      "getUserGetOperationsLimit",
      "getFilteredOperations",
      "getUserMainRole",
    ]),
    // Get Partners data
    Partners: function () {
      return this.getPartnersFor("STATS");
    },
    Pallets: function () {
      return this.getPallets;
    },
    // Get how operations in list was filtered
    FilteredOperations() {
      return this.getFilteredOperations;
    },
    // Items
    totalOperationsLength() {
      return this.Operations.length;
    },
    // Todo sorting > will probably need a custom sorting function
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Filtering
    // @TODO using : Provider https://bootstrap-vue.org/docs/components/table#using-items-provider-functions
    filteredItems() {
      // filteredItems(ctx, callback) {
      //  console.log("filteredItems:: currentPage", ctx.currentPage);
      return this.Operations.filter((item) => {
        // This is a basic equality filter. What I did in the actual code was to have an object with filter functions for each key. If a key was missing, it defaulted to straight equality.
        const areFieldsValid = this.fieldKeys.every((key) => this.selectVal[key] === undefined || item[key] === this.selectVal[key]);

        const isCharterClaimValid =
          this.filterChartersClaim === false ||
          (this.filterChartersClaim === true && item.isOS === true && item.OperationStatus === "L") ||
          (this.filterChartersClaim === true && item.isOS === false) ||
          (this.filterChartersClaim === true && item.isOS === true && item.MyRole === "ST" && item.HisRole === "MT");

        return areFieldsValid && isCharterClaimValid;
      });
    },
    fieldKeys() {
      return Object.keys(this.selectVal);
      //return Object.keys(this.Operations[0]);
    },
    listOptions() {
      // This could be simplified if the select was it's own component.
      const listOptions = {};
      this.fieldKeys.forEach((key) => {
        const vals = this.Operations.map((item) => item[key]);

        // Cheap and efficient unique.
        listOptions[key] = Array.from(new Set(vals));
      });

      return listOptions;
    },
    // DEV > Show stored datas
    StoredDatas() {
      return this.$store.state;
    },
  },
};
</script>
